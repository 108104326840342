export default new Map(
    [
        ['coffee', {
            label: 'coffee',
            title: 'قهوه',
            iconAddr: '/img/menu/category-icons/coffee.png',
            items: [
                {
                    label: 'espresso',
                    title: 'اسپرسو',
                    imageAddr: '/img/menu/coffee/4-921.jpg',
                    desc: '',
                    price: '۶۳',
                },
                {
                    label: 'double-espresso',
                    title: 'اسپرسو دبل',
                    imageAddr: '/img/menu/coffee/4-922.jpg',
                    desc: '',
                    price: '۷۳',
                },
                {
                    label: 'americano',
                    title: 'آمریکانو',
                    imageAddr: '/img/menu/coffee/4-923.jpg',
                    desc: '',
                    price: '۸۵',
                },
                {
                    label: 'cappuccino',
                    title: 'کاپوچینو',
                    imageAddr: '/img/menu/coffee/4-924.jpg',
                    desc: '',
                    price: '۸۹',
                },
                {
                    label: 'latte',
                    title: 'کافه لاته (سینگل شات/دبل شات)',
                    imageAddr: '/img/menu/coffee/4-925.jpg',
                    desc: '',
                    price: '۹۷/۸۹',
                },
                {
                    label: 'cortado',
                    title: 'کورتادو',
                    imageAddr: '/img/menu/coffee/4-926.jpg',
                    desc: '',
                    price: '۸۹',
                },
                {
                    label: 'mocha',
                    title: 'موکا',
                    imageAddr: '/img/menu/coffee/4-927.jpg',
                    desc: '',
                    price: '۹۹'
                },
                {
                    label: 'irish-coffee',
                    title: 'آیریش کافی',
                    imageAddr: '/img/menu/coffee/4-928.jpg',
                    desc: '',
                    price: '۹۹',
                },
                {
                    label: 'caramel-macchiato',
                    title: 'کارامل ماکیاتو',
                    imageAddr: '/img/menu/coffee/4-929.jpg',
                    desc: '',
                    price: '۹۹',
                },
                {
                    label: 'turkish-coffee',
                    title: 'قهوه ترک',
                    imageAddr: '/img/menu/coffee/4-9210.jpg',
                    desc: '',
                    price: '۷۷',
                },
                {
                    label: 'syrup',
                    title: 'سیروپ',
                    imageAddr: '',
                    desc: 'فندق، وانیل، کارامل، دارچین',
                    price: '۱۷',
                },
            ]
        }],
        ['brewed-coffee', {
            label: 'brewed-coffee',
            title: 'قهوه دمی',
            iconAddr: '/img/menu/category-icons/brewed-coffee.png',
            items: [
                {
                    label: 'one-chemex',
                    title: 'کمکس یک نفره',
                    imageAddr: '/img/menu/brewed-coffee/5-921.jpg',
                    desc: '',
                    price: '۱۱۷',
                },
                {
                    label: 'two-chemex',
                    title: 'کمکس دونفره',
                    imageAddr: '/img/menu/brewed-coffee/5-922.jpg',
                    desc: '',
                    price: '۱۷۵',
                },
                {
                    label: 'V60',
                    title: 'یک نفره/دونفره V60',
                    imageAddr: '/img/menu/brewed-coffee/5-923.jpg',
                    desc: '',
                    price: '۱۷۵/۱۱۷',
                },
                {
                    label: 'french-press',
                    title: 'فرنچ پرس',
                    imageAddr: '/img/menu/brewed-coffee/5-924.jpg',
                    desc: '',
                    price: '۱۱۹',
                },
            ]
        }],
        ['herbal-tea', {
            label: 'herbal-tea',
            title: 'دمنوش',
            iconAddr: '/img/menu/category-icons/herbal-tea.png',
            items: [
                {
                    label: 'makhsoos-ovan',
                    title: 'مخصوص اوان',
                    imageAddr: '/img/menu/herbal-tea/6-921.jpg',
                    desc: 'گل گاوزبان، بهار نارنج، دارچین و سنبل الطیب',
                    price: '۱۲۹',
                },
                {
                    label: 'chahar-fasl',
                    title: 'چهارفصل',
                    imageAddr: '/img/menu/herbal-tea/6-922.jpg',
                    desc: 'چای قرمز، گل گاوزبان، تخم گشنیز، هل و دارچین',
                    price: '۱۳۲',
                },
                {
                    label: 'arambakhsh',
                    title: 'آرام بخش',
                    imageAddr: '/img/menu/herbal-tea/6-923.jpg',
                    desc: 'بهار نارنج، دارچین، چای سیاه و بابونه',
                    price: '۱۲۵',
                },
                {
                    label: 'isgreen',
                    title: 'ایزگرین',
                    imageAddr: '/img/menu/herbal-tea/6-924.jpg',
                    desc: 'زنجبیل تازه، دارچین، لیمو، عسل و گل گاوزبان',
                    price: '۱۳۱',
                },
                {
                    label: 'sonnati',
                    title: 'سنتی',
                    imageAddr: '/img/menu/herbal-tea/6-925.jpg',
                    desc: 'شیر، عسل، هل، دارچین و زعفران',
                    price: '۱۳۵',
                },
                {
                    label: 'avirak',
                    title: 'اَویرَک',
                    imageAddr: '/img/menu/herbal-tea/6-926.jpg',
                    desc: 'پونه، آویشن، زنجبیل و عسل',
                    price: '۱۳۱',
                },
            ]
        }],
        ['hot-drink', {
            label: 'hot-drink',
            title: 'نوشیدنی گرم',
            iconAddr: '/img/menu/category-icons/hot-drink.png',
            items: [
                {
                    label: 'tea',
                    title: 'چای دمی',
                    imageAddr: '/img/menu/hot-drink/7-921.jpg',
                    desc: '',
                    price: '۷۷',
                },
                {
                    label: 'masala-tea',
                    title: 'چای ماسالا',
                    imageAddr: '/img/menu/hot-drink/7-922.jpg',
                    desc: '',
                    price: '۱۰۵',
                },
                {
                    label: 'english-tea',
                    title: 'چای انگلیسی',
                    imageAddr: '/img/menu/hot-drink/7-923.jpg',
                    desc: 'چای سیاه، عسل و شیر',
                    price: '۹۷',
                },
                {
                    label: 'latte-tea',
                    title: 'چای لاته',
                    imageAddr: '/img/menu/hot-drink/7-924.jpg',
                    desc: 'چای سیاه، شیر و کارامل',
                    price: '۹۹',
                },
                {
                    label: 'apple-cinnamon',
                    title: 'آب سیب و دارچین',
                    imageAddr: '/img/menu/hot-drink/7-925.jpg',
                    desc: '',
                    price: '۱۱۵',
                },
                {
                    label: 'hot-chocolate',
                    title: 'هات چاکلت',
                    imageAddr: '/img/menu/hot-drink/7-926.jpg',
                    desc: '',
                    price: '۱۲۵',
                },
                {
                    label: 'white-chocolate',
                    title: 'وایت چاکلت',
                    imageAddr: '/img/menu/hot-drink/7-927.jpg',
                    desc: '',
                    price: '۱۲۷',
                },
                {
                    label: 'cinnamon',
                    title: 'سینامون',
                    imageAddr: '/img/menu/hot-drink/7-928.jpg',
                    desc: 'شیر، دارچین، عسل و خامه',
                    price: '۱۲۱',
                },
                {
                    label: 'latte-macha',
                    title: 'ماچا لاته',
                    imageAddr: '/img/menu/hot-drink/7-929.jpg',
                    desc: '',
                    price: '۱۱۹',
                },
            ]
        }],
        ['ice-coffee', {
            label: 'ice-coffee',
            title: 'آیس کافی',
            iconAddr: '/img/menu/category-icons/ice-coffee.png',
            items: [
                {
                    label: 'ice-latte',
                    title: 'آیس لاته',
                    imageAddr: '/img/menu/ice-coffee/8-921.jpg',
                    desc: '',
                    price: '۹۷',
                },
                {
                    label: 'ice-mocha',
                    title: 'آیس موکا',
                    imageAddr: '/img/menu/ice-coffee/8-922.jpg',
                    desc: '',
                    price: '۹۹',
                },
                {
                    label: 'ice-americano',
                    title: 'آیس آمریکانو (دبل شات)',
                    imageAddr: '/img/menu/ice-coffee/8-923.jpg',
                    desc: '',
                    price: '۸۵',
                },
                {
                    label: 'ice-caramel-macchiato',
                    title: 'آیس کارامل ماکیاتو',
                    imageAddr: '/img/menu/ice-coffee/8-924.jpg',
                    desc: '',
                    price: '۹۹',
                },
            ]
        }],
        ['cold-drink', {
            label: 'cold-drink',
            title: 'نوشیدنی سرد',
            iconAddr: '/img/menu/category-icons/cold-drink.png',
            items: [
                {
                    label: 'kasni-golsorkh',
                    title: 'کاسنی و گل سرخ',
                    imageAddr: '/img/menu/cold-drink/9-921.jpg',
                    desc: '',
                    price: '۱۱۷',
                },
                {
                    label: 'khakshir',
                    title: 'خاکشیر',
                    imageAddr: '/img/menu/cold-drink/9-922.jpg',
                    desc: '',
                    price: '۱۱۵',
                },
                {
                    label: 'bidmeshk-tokhmsharbati',
                    title: 'بیدمشک و تخم شربتی',
                    imageAddr: '/img/menu/cold-drink/9-923.jpg',
                    desc: '',
                    price: '۱۲۳',
                },
                {
                    label: 'khiar-sekanjabin',
                    title: 'خیار و سکنجبین',
                    imageAddr: '/img/menu/cold-drink/9-924.jpg',
                    desc: '',
                    price: '۱۱۵',
                },
                {
                    label: 'bidmeshk-zaferon',
                    title: 'بیدمشک و زعفران',
                    imageAddr: '/img/menu/cold-drink/9-925.jpg',
                    desc: '',
                    price: '۱۲۳',
                },
                {
                    label: 'abmive-taze',
                    title: 'آب میوه تازه',
                    imageAddr: '',
                    desc: '',
                    price: '--',
                },
                {
                    label: 'ice-macha',
                    title: 'آیس ماچا',
                    imageAddr: '/img/menu/cold-drink/9-927.jpg',
                    desc: '',
                    price: '۱۱۹',
                },
                {
                    label: 'bermonda',
                    title: 'برموندا',
                    imageAddr: '/img/menu/cold-drink/9-928.jpg',
                    desc: 'سودا، لیمو و توت فرنگی',
                    price: '۶۹',
                },
            ]
        }],
        ['cocktail', {
            label: 'cocktail',
            title: 'کوکتل',
            iconAddr: '/img/menu/category-icons/cocktail.png',
            items: [
                {
                    label: 'ovan',
                    title: 'اُوان',
                    imageAddr: '/img/menu/cocktail/10-921.jpg',
                    desc: 'پرتقال، انبه، توت فرنگی و بلوکروکائو',
                    price: '۱۳۳',
                },
                {
                    label: 'mojito',
                    title: 'مخیتو',
                    imageAddr: '/img/menu/cocktail/10-922.jpg',
                    desc: 'نعنا تازه، لیموترش تازه، شربت نعنا و سودا',
                    price: '۱۲۹',
                },
                {
                    label: 'limonade',
                    title: 'لیموناد',
                    imageAddr: '/img/menu/cocktail/10-923.jpg',
                    desc: 'اسپرایت، لیموترش تازه و نعنا',
                    price: '۱۳۳',
                },
                {
                    label: 'san-jose',
                    title: 'سن خوزه',
                    imageAddr: '/img/menu/cocktail/10-924.jpg',
                    desc: 'آب انار، پرتقال و هایپ',
                    price: '۱۲۹',
                },
                {
                    label: 'greenland',
                    title: 'گرین لند',
                    imageAddr: '/img/menu/cocktail/10-925.jpg',
                    desc: 'آب سیب، لیموترش تازه و نعنا',
                    price: '۱۳۵',
                },
                {
                    label: 'martino',
                    title: 'مارتینو',
                    imageAddr: '/img/menu/cocktail/10-926.jpg',
                    desc: 'آب آناناس، پرتقال و گرانادین',
                    price: '۱۳۳',
                },
                {
                    label: 'light-night',
                    title: 'لایت نایت',
                    imageAddr: '/img/menu/cocktail/10-927.jpg',
                    desc: 'آب زرشک، آلبالو و انار',
                    price: '۱۲۹',
                },
                {
                    label: 'garden',
                    title: 'گاردن',
                    imageAddr: '/img/menu/cocktail/10-928.jpg',
                    desc: 'آب پرتقال، انبه و نعنا',
                    price: '۱۲۵',
                },
                {
                    label: 'jalapeno',
                    title: 'آلوپینو',
                    imageAddr: '/img/menu/cocktail/10-929.jpg',
                    desc: 'خیار، آلوئه ورا، نعنا و لیموترش',
                    price: '۱۳۹',
                },
                {
                    label: 'rose-velvet',
                    title: 'رزولوت',
                    imageAddr: '/img/menu/cocktail/10-9210.jpg',
                    desc: 'آب انار، آلبالو و شاه توت',
                    price: '۱۳۱',
                },
                {
                    label: 'long-time',
                    title: 'لانگ تایم',
                    imageAddr: '/img/menu/cocktail/10-9211.jpg',
                    desc: 'آب انبه، توت فرنگی و هایپ',
                    price: '۱۳۱',
                },
            ]
        }],
        ['smoothie', {
            label: 'smoothie',
            title: 'اسموتی',
            iconAddr: '/img/menu/category-icons/smoothie.png',
            items: [
                {
                    label: 'summer-berry',
                    title: 'سامربری',
                    imageAddr: '/img/menu/smoothie/11-921.jpg',
                    desc: 'شاه توت، آناناس و توت فرنگی',
                    price: '۱۴۵',
                },
                {
                    label: 'camilo',
                    title: 'کامیلو',
                    imageAddr: '/img/menu/smoothie/11-922.jpg',
                    desc: 'زرشک، انار و توت فرنگی',
                    price: '۱۳۵',
                },
                {
                    label: 'fresh-mix',
                    title: 'فرش میکس',
                    imageAddr: '/img/menu/smoothie/11-923.jpg',
                    desc: 'موز، آناناس و توت فرنگی',
                    price: '۱۴۵',
                },
                {
                    label: 'havana',
                    title: 'هاوانا',
                    imageAddr: '/img/menu/smoothie/11-924.jpg',
                    desc: 'انبه، موز و آناناس',
                    price: '۱۳۷',
                },
                {
                    label: 'carito',
                    title: 'کاریتو',
                    imageAddr: '/img/menu/smoothie/11-925.jpg',
                    desc: 'موز، توت فرنگی و پرتقال',
                    price: '۱۴۳',
                },
                {
                    label: 'shine',
                    title: 'شاین',
                    imageAddr: '/img/menu/smoothie/11-926.jpg',
                    desc: 'انبه، شاه توت و موز',
                    price: '۱۴۷',
                },
            ]
        }],
        ['milk-shake', {
            label: 'milk-shake',
            title: 'شیک',
            iconAddr: '/img/menu/category-icons/milk-shake.png',
            items: [
                {
                    label: 'chocolate-shake',
                    title: 'شیک شکلات',
                    imageAddr: '/img/menu/milk-shake/12-921.jpg',
                    desc: '',
                    price: '۱۳۷',
                },
                {
                    label: 'chocolate-banana-shake',
                    title: 'شیک موز شکلات',
                    imageAddr: '/img/menu/milk-shake/12-922.jpg',
                    desc: '',
                    price: '۱۴۷',
                },
                {
                    label: 'nutella-shake',
                    title: 'شیک نوتلا',
                    imageAddr: '/img/menu/milk-shake/12-923.jpg',
                    desc: '',
                    price: '۱۵۷',
                },
                {
                    label: 'nutella-espresso-shake',
                    title: 'شیک نوتلا اسپرسو',
                    imageAddr: '/img/menu/milk-shake/12-924.jpg',
                    desc: '',
                    price: '۱۶۱',
                },
                {
                    label: 'nutella-banana-shake',
                    title: 'شیک موز نوتلا',
                    imageAddr: '/img/menu/milk-shake/12-925.jpg',
                    desc: '',
                    price: '۱۶۱',
                },
                {
                    label: 'lotus-shake',
                    title: 'شیک لوتوس',
                    imageAddr: '/img/menu/milk-shake/12-926.jpg',
                    desc: '',
                    price: '۱۶۱',
                },
                {
                    label: 'peanut-shake',
                    title: 'شیک بادام زمینی',
                    imageAddr: '/img/menu/milk-shake/12-927.jpg',
                    desc: '',
                    price: '۱۵۷',
                },
                {
                    label: 'nutella-peanut-shake',
                    title: 'شیک نوتلا بادام زمینی',
                    imageAddr: '/img/menu/milk-shake/12-928.jpg',
                    desc: '',
                    price: '۱۷۵',
                },
                {
                    label: 'apple-cinnamon-shake',
                    title: 'شیک سیب و دارچین',
                    imageAddr: '/img/menu/milk-shake/12-929.jpg',
                    desc: '',
                    price: '۱۵۵',
                },
                {
                    label: 'coffee-shake',
                    title: 'شیک قهوه',
                    imageAddr: '/img/menu/milk-shake/12-9210.jpg',
                    desc: 'بستنی وانیل به همراه یک شات اسپرسو',
                    price: '۱۵۱',
                },
            ]
        }],
        ['dessert', {
            label: 'dessert',
            title: 'دسر',
            iconAddr: '/img/menu/category-icons/dessert.png',
            items: [
                {
                    label: 'farma-cope',
                    title: 'فارماکوپه',
                    imageAddr: '/img/menu/dessert/13-921.jpg',
                    desc: 'بستنی، نوتلا، کرن فلکس و میوه های تازه فصل',
                    price: '۲۱۱',
                },
                {
                    label: 'yogurt-granola',
                    title: 'ماست گرانولا',
                    imageAddr: '/img/menu/dessert/13-922.jpg',
                    desc: '',
                    price: '۱۷۹',
                },
                {
                    label: 'nutella-crepe',
                    title: 'کرپ نوتلا',
                    imageAddr: '/img/menu/dessert/13-923.jpg',
                    desc: '',
                    price: '۱۹۵',
                },
                {
                    label: 'nutella-peanut-crepe',
                    title: 'کرپ نوتلا بادام زمینی',
                    imageAddr: '/img/menu/dessert/13-924.jpg',
                    desc: '',
                    price: '۲۰۵',
                },
                {
                    label: 'affogato',
                    title: 'آفوگاتو',
                    imageAddr: '/img/menu/dessert/13-925.jpg',
                    desc: '',
                    price: '۸۷',
                },
            ]
        }],
        ['cake', {
            label: 'cake',
            title: 'کیک',
            iconAddr: '/img/menu/category-icons/cake.png',
            items: [
                {
                    label: 'chocolate-lava',
                    title: 'چاکلت لاوا (سوفله شکلات)',
                    imageAddr: '/img/menu/cake/14-921.jpg',
                    desc: '',
                    price: '۲۱۵',
                },
                {
                    label: 'carrot-cake',
                    title: 'کیک هویج',
                    imageAddr: '/img/menu/cake/14-922.jpg',
                    desc: '',
                    price: '۱۰۹',
                },
                {
                    label: 'caramel-cheese-cake',
                    title: 'چیزکیک کارامل',
                    imageAddr: '/img/menu/cake/14-923.jpg',
                    desc: '',
                    price: '۱۲۱',
                },
                {
                    label: 'nutella-cheese-cake',
                    title: 'چیزکیک نوتلا',
                    imageAddr: '/img/menu/cake/14-924.jpg',
                    desc: '',
                    price: '۱۲۹',
                },
                {
                    label: 'german-apple',
                    title: 'سیب آلمانی',
                    imageAddr: '/img/menu/cake/14-925.jpg',
                    desc: '',
                    price: '۱۲۷',
                },
                {
                    label: 'double-chocolate-cake',
                    title: 'کیک دبل چاکلت',
                    imageAddr: '/img/menu/cake/14-926.jpg',
                    desc: '',
                    price: '۱۱۷',
                },
                {
                    label: 'mars-cheese-cake',
                    title: 'چیزکیک مارس',
                    imageAddr: '/img/menu/cake/14-927.jpg',
                    desc: '',
                    price: '۱۲۷',
                },
                {
                    label: 'black-forest-beraniz',
                    title: 'برانیز بلک فارست',
                    imageAddr: '/img/menu/cake/14-928.jpg',
                    desc: '',
                    price: '۱۲۵',
                },
            ]
        }],
        ['salad', {
            label: 'salad',
            title: 'سالاد',
            iconAddr: '/img/menu/category-icons/salad.png',
            items: [
                {
                    label: 'cesar-salad',
                    title: 'سالاد سزار',
                    imageAddr: '/img/menu/salad/2-921.jpg',
                    desc: 'کاهو، نان سیر، فیله مرغ و سس سزار',
                    price: '۲۲۹',
                },
                {
                    label: 'mediterranean-salad',
                    title: 'سالاد مدیترانه',
                    imageAddr: '/img/menu/salad/2-922.jpg',
                    desc: 'کاهو، اسفناج، تربچه، پیازچه، گوجه گیلاسی، پنیر لیقوان و سبزیجات معطر',
                    price: '۲۷۳',
                },
                {
                    label: 'fruits-spinach-salad',
                    title: 'سالاد میوه و اسفناج',
                    imageAddr: '/img/menu/salad/2-923.jpg',
                    desc: 'کاهو، اسفناج، ریحان و میوه های تازه فصل',
                    price: '۲۷۷',
                },
                {
                    label: 'ovan-salad',
                    title: 'سالاد اوان',
                    imageAddr: '/img/menu/salad/2-924.jpg',
                    desc: 'کاهو، گوجه گیلاسی، بلوچیز، سیب، گردو، عسل و سس مخصوص',
                    price: '۲۷۵',
                },
            ]
        }],
        ['food', {
            label: 'food',
            title: 'غذا',
            iconAddr: '/img/menu/category-icons/food.png',
            items: [
                {
                    label: 'alfredo-penne',
                    title: 'پنه آلفردو',
                    imageAddr: '/img/menu/food/1-921.jpg',
                    desc: 'فیله مرغ، قارچ و سس خامه',
                    price: '۲۸۷',
                },
                {
                    label: 'cheese-penne',
                    title: 'پنه چیز',
                    imageAddr: '/img/menu/food/1-922.jpg',
                    desc: 'ژامبون مرغ، پنیر گودا، بلوچیز و قارچ',
                    price: '۲۹۷',
                },
                {
                    label: 'sicily-penne',
                    title: 'پنه سیسیلی',
                    imageAddr: '/img/menu/food/1-923.jpg',
                    desc: 'بیکن، خامه، پنیر گودا و جعفری',
                    price: '۳۱۱',
                },
                {
                    label: 'vegetables-penne',
                    title: 'پنه سبزیجات',
                    imageAddr: '/img/menu/food/1-924.jpg',
                    desc: 'سس گوجه، قارچ، هویج و فلفل دلمه ای',
                    price: '۲۶۳',
                },
                {
                    label: 'roast-beef-panini',
                    title: 'پنینی رست بیف',
                    imageAddr: '/img/menu/food/1-925.jpg',
                    desc: 'رست بیف، پیازچه، گوجه فرنگی و بلوچیز',
                    price: '۳۱۱',
                },
                {
                    label: 'chicken-fillet-panini',
                    title: 'پنینی فیله مرغ',
                    imageAddr: '/img/menu/food/1-926.jpg',
                    desc: 'فیله مرغ، پیازچه، گوجه فرنگی و پنیر گودا',
                    price: '۲۵۵',
                },
                {
                    label: 'chicken-eggplant-panini',
                    title: 'پنینی مرغ و بادمجان',
                    imageAddr: '/img/menu/food/1-927.jpg',
                    desc: 'فیله مرغ، بادمجان، پیازچه، جعفری، گوجه فرنگی و پنیر گودا',
                    price: '۲۵۹',
                },
                {
                    label: 'turkey-panini',
                    title: 'پنینی بوقلمون',
                    imageAddr: '/img/menu/food/1-928.jpg',
                    desc: 'ژامبون بوقلمون، گوجه فرنگی، پیازچه، جعفری، اسفناج و پنیر گودا',
                    price: '۲۴۹',
                },
                {
                    label: 'vegetables-panini',
                    title: 'پنینی سبزیجات',
                    imageAddr: '/img/menu/food/1-929.jpg',
                    desc: 'پنیر خامه ای، پنیر گودا، اسفناج، جعفری، ریحان و گوجه فرنگی',
                    price: '۲۳۹',
                },
                {
                    label: 'chicken-fillet-plate',
                    title: 'بشقاب فیله مرغ',
                    imageAddr: '/img/menu/food/1-9210.jpg',
                    desc: '',
                    price: '۳۲۵',
                },
                {
                    label: 'chicken-julienne',
                    title: 'ژولین مرغ',
                    imageAddr: '/img/menu/food/1-9211.jpg',
                    desc: 'قارچ، فیله مرغ، خامه و سبزیجات معطر',
                    price: '۲۹۵',
                },
                {
                    label: 'potato-gratin',
                    title: 'گراتن سیب زمینی',
                    imageAddr: '/img/menu/food/1-9212.jpg',
                    desc: 'پوره سیب زمینی، پنیر خامه ای و بیکن',
                    price: '۲۱۹',
                },
            ]
        }],
        ['breakfast', {
            label: 'breakfast',
            title: 'صبحانه',
            iconAddr: '/img/menu/category-icons/breakfast.png',
            items: [
                {
                    label: 'spanish-omelette',
                    title: 'املت اسپانیایی',
                    imageAddr: '/img/menu/breakfast/3-921.jpg',
                    desc: 'تخم مرغ، سوسیس، گوجه فرنگی، فلفل دلمه و پنیر گودا',
                    price: '۲۷۹',
                },
                {
                    label: 'bacon-fried-eggs',
                    title: 'نیمرو بیکن',
                    imageAddr: '/img/menu/breakfast/3-922.jpg',
                    desc: 'تخم مرغ، بیکن، اسفناج و سوسیس',
                    price: '۳۱۱',
                },
                {
                    label: 'cheese-fried-eggs',
                    title: 'نیمرو پنیر',
                    imageAddr: '/img/menu/breakfast/3-923.jpg',
                    desc: 'تخم مرغ، پنیر بلوچیز و پنیر گودا',
                    price: '۱۵۱',
                },
                {
                    label: 'iranian-omelette',
                    title: 'املت ایرانی',
                    imageAddr: '/img/menu/breakfast/3-924.jpg',
                    desc: '',
                    price: '۱۴۹',
                },
                {
                    label: 'cold-breakfast-plate',
                    title: 'بشقاب سرد',
                    imageAddr: '/img/menu/breakfast/3-925.jpg',
                    desc: 'کره، پنیر، عسل، خامه، مربا، خیار، گوجه فرنگی و گردو',
                    price: '۳۰۷',
                },
                {
                    label: 'bacon-eggs-croissant',
                    title: 'کروسان بیکن تخم مرغ',
                    imageAddr: '/img/menu/breakfast/3-926.jpg',
                    desc: '',
                    price: '۲۱۹',
                },
                {
                    label: 'cheese-walnut-basil-croissant',
                    title: 'کروسان پنیر گردو ریحان',
                    imageAddr: '/img/menu/breakfast/3-927.jpg',
                    desc: '',
                    price: '۱۹۱',
                },
                {
                    label: 'nutella-banana-croissant',
                    title: 'کروسان موز نوتلا',
                    imageAddr: '/img/menu/breakfast/3-928.jpg',
                    desc: '',
                    price: '۲۰۹',
                },
            ]
        }],
    ]
)